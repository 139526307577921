import React from 'react';
import { useSelector } from 'react-redux';

import { serializeColumnIdentifier } from '@neptune/shared/leaderboard-domain';

import { AttributeDefinition } from 'domain/experiment/attribute';
import { getLeaderboardPublicColumnsForCurrentProject } from 'state/ui/leaderboards/ordered-columns/selectors';

export const useAttributeDefinitionsFromCurrentColumns = (): AttributeDefinition[] => {
  const columns = useSelector(getLeaderboardPublicColumnsForCurrentProject);
  const columnsHash = columns.map(serializeColumnIdentifier).join();
  return React.useMemo(
    () =>
      columns.map(({ id: name, columnType: type, aggregationMode: subproperty, displayName }) => ({
        name,
        type,
        subproperty,
        displayName,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsHash],
  );
};
