import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveProjectsUsage } from '@neptune/pricing-workspace-status-api';
import { archiveProject, unarchiveProject } from '@neptune/project-business-logic';
import { SubviewEntry } from '@neptune/project-domain';
import { DotsHButton, InfoTooltip, PaymentsLink } from '@neptune/shared/common-ui';
import { useLocalModal } from '@neptune/shared/common-util';
import {
  getActiveProjectQuota,
  hasCapabilitiesToUnarchiveProject,
  isArchivingEnabled,
} from '@neptune/shared/core-organizations-business-logic';
import {
  hasProjectArchivePermission,
  hasProjectDeletePermission,
  hasProjectUnarchivePermission,
} from '@neptune/shared/core-permissions-business-logic';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { UnsavedChangesModal, UnsavedChangesReason } from '@neptune/shared/lb-views-ui';
import { Dropdown, DropdownProps, DropdownToggleProps } from '@neptune/shared/venus-ui';

import { getContextOrganization } from 'state/selectors-global';
import { AppState } from 'state/types';
import { requestDeleteProject } from 'state/ui/modals/project/actions';

import { ShareMenuItemContainer } from './ShareMenuItemContainer';
import { ShareModalContainer } from './ShareModalContainer';

type ProjectDropdownMenuContainerProps = {
  openSubview: (subviewId: string, project: ProjectWithRole) => void;
  subviewEntries: SubviewEntry[];
  project: ProjectWithRole;
  toggle?: React.ReactElement<DropdownToggleProps>;
} & Pick<DropdownProps, 'attachment' | 'targetAttachment' | 'menuAnimation'>;

export const ProjectDropdownMenuContainer: React.FC<ProjectDropdownMenuContainerProps> = ({
  project,
  openSubview,
  subviewEntries,
  toggle,
  attachment = 'top right',
  targetAttachment = 'bottom right',
  menuAnimation = { direction: 'top-bottom' },
}) => {
  const dispatch = useDispatch();

  const workspace = useSelector(getContextOrganization);

  const activeProjects = useSelector((state: AppState) =>
    workspace ? selectActiveProjectsUsage(state, workspace.name) : undefined,
  );
  const activeProjectQuota = workspace && getActiveProjectQuota(workspace.traits);

  const canDelete = hasProjectDeletePermission(project);
  const canArchive = hasProjectArchivePermission(project);
  const canUnarchive =
    !!workspace &&
    hasCapabilitiesToUnarchiveProject(workspace.traits, activeProjects) &&
    hasProjectUnarchivePermission(project);

  const archivingEnabled = workspace ? isArchivingEnabled(workspace.traits) : false;

  const handleArchive = React.useCallback(() => {
    if (canArchive && project) {
      dispatch(archiveProject(project));
    }
  }, [canArchive, dispatch, project]);

  const handleUnarchive = React.useCallback(() => {
    if (canUnarchive && project) {
      dispatch(unarchiveProject(project));
    }
  }, [canUnarchive, dispatch, project]);

  const {
    isOpen: shareModalIsOpen,
    close: closeShareModal,
    open: openShareModal,
  } = useLocalModal();

  const {
    isOpen: unsavedChangesModalIsOpen,
    close: closeUnsavedChangesModal,
    open: openUnsavedChangesModal,
  } = useLocalModal();

  const unarchiveDisabledReason = (
    <span>
      You’ve reached the limit of active projects $
      {activeProjectQuota == null ? '' : ' (' + activeProjectQuota + ')'}.
      <br />
      Increase the limit in the{' '}
      <PaymentsLink organizationName={workspace?.name} children="Billing & payments" /> section of
      your workspace settings, or archive an active project.
    </span>
  );

  return (
    <>
      <Dropdown
        toggle={toggle || <DotsHButton data-role="project-menu-button" />}
        attachment={attachment}
        targetAttachment={targetAttachment}
        menuAnimation={menuAnimation}
        constraints={[{ to: 'window', attachment: 'together', pin: true }]}
      >
        {({ collapse }) => (
          <Dropdown.Menu data-role="project-dropdown-menu">
            <Dropdown.Group>
              {subviewEntries.map(({ disabled, disabledReason, id, label, icon }) => (
                <InfoTooltip show={disabled} placement="left-center" text={disabledReason} key={id}>
                  <Dropdown.Item
                    data-item={id}
                    label={label}
                    onClick={() => {
                      openSubview(id, project);
                      collapse();
                    }}
                    disabled={disabled}
                    icon={icon}
                  />
                </InfoTooltip>
              ))}
            </Dropdown.Group>
            <Dropdown.Group>
              <ShareMenuItemContainer
                openShareModal={openShareModal}
                openUnsavedChangesModal={openUnsavedChangesModal}
                onClick={() => collapse()}
              />
            </Dropdown.Group>
            <Dropdown.Group>
              {project && archivingEnabled && !project.archived && (
                <Dropdown.Item
                  label="Archive project"
                  icon="box-archive"
                  disabled={!canArchive}
                  onClick={handleArchive}
                  data-item="archive-project"
                />
              )}
              {project && archivingEnabled && project.archived && (
                <InfoTooltip
                  show={!canUnarchive}
                  text={unarchiveDisabledReason}
                  placement="right-center"
                >
                  <Dropdown.Item
                    label="Unarchive project"
                    icon="box-archive"
                    disabled={!canUnarchive}
                    onClick={handleUnarchive}
                    data-item="unarchive-project"
                  />
                </InfoTooltip>
              )}
              <InfoTooltip
                show={!canDelete}
                placement="left-center"
                text="You need to be a workspace admin to delete the project."
              >
                <Dropdown.Item
                  data-item="delete-project"
                  label="Delete project"
                  icon="trash"
                  disabled={!canDelete}
                  onClick={() => {
                    dispatch(
                      requestDeleteProject(project, {
                        forwardToOrganization: workspace?.name,
                      }),
                    );
                    collapse();
                  }}
                />
              </InfoTooltip>
            </Dropdown.Group>
          </Dropdown.Menu>
        )}
      </Dropdown>
      <ShareModalContainer isOpen={shareModalIsOpen} close={closeShareModal} project={project} />
      <UnsavedChangesModal
        reason={UnsavedChangesReason.Share}
        isOpen={unsavedChangesModalIsOpen}
        onClose={closeUnsavedChangesModal}
      />
    </>
  );
};
