// Libs
import React from 'react';
import { useSelector } from 'react-redux';

// App
import { UserAvatar, UserAvatarProps } from '@neptune/shared/avatars-ui';

import { getCurrentUser } from 'state/users/current-user/selectors';

// Module
export const UserAvatarContainer: React.FC<UserAvatarProps> = (passedProps) => {
  const currentUser = useSelector(getCurrentUser);
  const avatarProps = {
    ...passedProps,
    ...(currentUser && passedProps.username === currentUser.username
      ? { imageSrc: currentUser.avatarUrl }
      : null),
  };

  return <UserAvatar {...avatarProps} />;
};
