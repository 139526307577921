import { registerOverlaySubview } from '@neptune/project-domain';
import { ProjectServiceAccounts } from '@neptune/service-accounts-feature';
import {
  hasProjectServiceAccountPermissions,
  hasProjectSettingsAccessPermission,
  hasProjectUsageAccessPermission,
} from '@neptune/shared/core-permissions-business-logic';

import { People } from 'views/project-dashboard/views/settings/People';
import { Properties } from 'views/project-dashboard/views/settings/Properties';
import { Usage } from 'views/project-dashboard/views/settings/Usage';

registerOverlaySubview({
  label: 'Details & privacy',
  icon: 'edit',
  id: 'properties',
  Component: Properties,
  permission: hasProjectSettingsAccessPermission,
  noPermissionCopy: 'You need to be a project owner to edit project details.',
});

registerOverlaySubview({
  label: 'Usage',
  icon: 'gauge',
  id: 'usage',
  Component: Usage,
  permission: hasProjectUsageAccessPermission,
  noPermissionCopy: 'You need to be a project owner or contributor to view usage details.',
});

registerOverlaySubview({
  label: 'People',
  icon: 'key',
  id: 'people',
  Component: People,
});

registerOverlaySubview({
  label: 'Service accounts',
  icon: 'service-account',
  id: 'service-accounts',
  Component: ProjectServiceAccounts,
  permission: hasProjectServiceAccountPermissions,
  noPermissionCopy: 'You need to be a project owner to assign service accounts.',
});
