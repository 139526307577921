import urlParse from 'url-parse';

import { FeatureFlags } from '@neptune/config-core-domain';

import { createSessionStorageAccessor } from 'common/storage-session';

import config from 'config';

import { FeatureFlag, FeatureFlagConfig, featureFlags } from './featureFlag-types';

let featureFlagsInitialized = false;

export type FeatureFlagState = Record<FeatureFlag, boolean | undefined> &
  Record<FeatureFlagConfig, any>;

const sessionStorage = createSessionStorageAccessor<FeatureFlagState>();

function isFeatureFlag(text: any): text is FeatureFlag {
  return featureFlags.includes(text);
}

export function handleFeatureFlags() {
  const urlParams = urlParse(window.location.search, true);
  const setFeatureFlag = urlParams.query['setFeatureFlag'];
  const resetFeatureFlag = urlParams.query['resetFeatureFlag'];

  setFeatureFlag
    ?.split('~')
    .filter(isFeatureFlag)
    .forEach((flag) => {
      sessionStorage.setItem(flag, true);

      const featureFlagConfig = urlParams.query['featureFlagConfig'];

      if (featureFlagConfig !== undefined) {
        sessionStorage.setItem(`${flag}Config` as const, featureFlagConfig);
      }
    });

  resetFeatureFlag
    ?.split('~')
    .filter(isFeatureFlag)
    .forEach((flag) => {
      sessionStorage.setItem(flag, false);
    });

  featureFlagsInitialized = true;
}

export const getAdminPanelEnabled = createFlagGetter('withAdminPanel');

export const getRefactoredChartsEnabled = createFlagGetter('withRefactoredCharts');
export const getRefactoredCharts2Enabled = createFlagGetter('withRefactoredCharts2');
export const getRefactoredCharts3Enabled = createFlagGetter('withRefactoredCharts3');

export const getRunGroupsV4Enabled = createFlagGetter('withRunGroupsV4');

export const getSingleRunAutoFollowEnabled = () => createFlagGetter('withSingleRunAutoFollow')();

export const getSectionsInReports = createFlagGetter('withSectionsInReports');
export const getSectionsInDashboards = createFlagGetter('withSectionsInDashboards');

export const getXAxisGlobalSettingEnabled = createFlagGetter('withXAxisGlobalSettings');

export const getNameSearchRegexpEnabled = createFlagGetter('withNameSearchRegExp');

export const getCompoundSearch = createFlagGetter('withCompoundSearch');
export const getCompoundSearchEditableItems = createFlagGetter(
  'withCompoundSearchWithEditableCriterionItems',
);

export const getExportToExistingReports = createFlagGetter('withExportToExistingReports');

export const getGlobalStepsRangeFilterEnabled = createFlagGetter('withGlobalStepsRangeFilter');

export const getNewAddEditWidgetModalEnabled = createFlagGetter('withNewAddEditWidgetModal');

export const getBulkExportToExistingReports = () =>
  getExportToExistingReports() && createFlagGetter('withBulkExportToExistingReports')();

export function getFeatureFlagConfig(key: FeatureFlag) {
  const value = sessionStorage.getItem(`${key}Config` as const);

  try {
    return JSON.parse(value);
  } catch (e) {}
}

function createFlagGetter(key: FeatureFlag & keyof FeatureFlags): () => boolean {
  if (!featureFlagsInitialized) {
    handleFeatureFlags();
  }

  return () => sessionStorage.getItem(key) ?? config[key] ?? false;
}
