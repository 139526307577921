// Libs
import { ParserRuleContext } from 'antlr4';
import { ParseTreeVisitor, TerminalNode } from 'antlr4/tree/Tree';
// App
import {
  AggrIdentifierContext,
  AndLogicalExprP2Context,
  BasicIdentifierContext,
  ContainsRelationExprContext,
  EqRelationExprContext,
  ExistsLogicalExprP3Context,
  GeRelationExprContext,
  GtRelationExprContext,
  LeRelationExprContext,
  LtRelationExprContext,
  MatchesRelationExprContext,
  NeRelationExprContext,
  OrLogicalExprP1Context,
  ParenthesesLogicalExprP3Context,
  QueryContext,
  QuotedStringContext,
  QuotedStringIdentifierContext,
  RelationLogicalExprP3Context,
  SimpleStringContext,
  SimpleStringIdentifierContext,
  SingleLogicalExprP1Context,
} from 'generated/nql/NQLParser';

import { stringToBoolean } from 'common/parse';
import { KnownAttributes } from 'domain/experiment/attribute';

import { nqlOperatorToOperatorMap } from './operator-map';
import { isPeriodValue } from './period-map';
import {
  EmptyCriterion,
  PartialSearchCriterionWithAttributeAndType,
  SearchCriterion,
  SearchCriterionWithValue,
  SearchQuery,
  SearchQueryOperator,
} from './search-query-model';
import { criterionHasValue, isSearchQuery } from './search-query-model-utils';
import { unescapeSpecialCharacters } from './special-character-handling';

// manually extend with missing typings
interface EnhancedAggregatedIdentifierContext extends AggrIdentifierContext {
  SIMPLE_STRING(index: number): TerminalNode;
}

export class ParseQueryVisitor extends ParseTreeVisitor {
  visitChildren(ctx: ParserRuleContext) {
    // convert relation logical expresion into a SearchCriterion object
    if (ctx instanceof RelationLogicalExprP3Context) {
      return convertRelationExpression(ctx);
    }

    // convert existence logical expression into a valueless SearchCriterion object
    if (ctx instanceof ExistsLogicalExprP3Context) {
      return convertExistenceExpression(ctx);
    }

    // skip straight into the child expression in case of wrapper contexts, e.g. SingleLogicalExprP1Context
    if (ctx.getChildCount() === 1 || ctx instanceof QueryContext) {
      return ctx.getChild(0).accept(this);
    }

    // skip straight into the expression wrapped with parentheses
    if (ctx instanceof ParenthesesLogicalExprP3Context) {
      return ctx.getChild(1).accept(this);
    }

    // convert logical expression into a SearchQuery object
    if (ctx instanceof AndLogicalExprP2Context || ctx instanceof OrLogicalExprP1Context) {
      const criteria: Array<SearchQuery | SearchCriterion> = [];
      const operator = ctx instanceof AndLogicalExprP2Context ? 'and' : 'or';

      for (let i = 0; i < ctx.getChildCount(); i += 1) {
        const child = ctx.getChild(i);

        // skip operator nodes (childless) since we can derive operator from the context class
        if (child.getChildCount() > 0) {
          // launch visitor inside the child node
          const result = child.accept(this);

          if (result) {
            criteria.push(result);
          }
        }
      }

      const wrappedInParens =
        ctx.parentCtx instanceof ParenthesesLogicalExprP3Context ||
        // due to the NQL grammar's nature, the AND logical expression has an extra parent context inside the wrapping parens
        // hence this check for grandparent's context
        (ctx.parentCtx instanceof SingleLogicalExprP1Context &&
          ctx.parentCtx.parentCtx instanceof ParenthesesLogicalExprP3Context);

      return flattenCriteria(
        {
          criteria,
          operator,
        },
        wrappedInParens,
      );
    }
  }
}

// e.g. "aggregate(attribute:type) operator value" -> {attribute, subproperty?, type, operator, value}
function convertRelationExpression(ctx: RelationLogicalExprP3Context) {
  const expression = ctx.relationExpr();

  if (!expression) {
    return;
  }

  const criterion = getPartialCriterion(expression.getChild(0));
  const operator = getOperator(expression);
  const value = getValue(expression);

  if (!criterion || !operator || value == null) {
    return;
  }

  const parentParens = getParentParenthesesContext(ctx);

  // wrapped in double parens and no logical expression inside the outer parens
  if (parentParens) {
    const grandparentParens = getParentParenthesesContext(parentParens);

    if (grandparentParens && !getParentLogicalContextInsideContext(ctx, grandparentParens)) {
      return convertToSingleCriterion([
        {
          ...criterion,
          operator,
          value,
        } as SearchCriterionWithValue,
      ]);
    }
  }

  return {
    ...criterion,
    operator,
    value,
  } as SearchCriterion;
}

// e.g. "attribute:type EXISTS" -> {attribute, type, operator}
function convertExistenceExpression(ctx: ExistsLogicalExprP3Context) {
  const criterion = getPartialCriterion(ctx.identifier());
  const operator = getOperator(ctx);

  if (!criterion || !operator) {
    return;
  }

  return {
    ...criterion,
    operator,
  } as EmptyCriterion;
}

// e.g. "foo" or "`foo`" -> foo
function getIdentifier(ctx: ParserRuleContext) {
  if (ctx instanceof QuotedStringIdentifierContext) {
    const text = ctx.BACK_QUOTED_STRING().getText();
    return text.slice(1, text.length - 1);
  }

  if (ctx instanceof SimpleStringIdentifierContext) {
    return ctx.SIMPLE_STRING().getText();
  }
}

// e.g. 'foo' or '"foo"' -> foo
function getStringValue(ctx: ParserRuleContext): string {
  if (ctx instanceof QuotedStringContext) {
    const text = ctx.DOUBLE_QUOTED_STRING().getText();
    return text.slice(1, text.length - 1);
  }

  if (ctx instanceof SimpleStringContext) {
    return ctx.SIMPLE_STRING().getText();
  }

  return ctx.getText();
}

// e.g. "attribute:type" or "average(attribute:type)" -> {attribute, subproperty?, type}
function getPartialCriterion(
  ctx: ParserRuleContext,
): PartialSearchCriterionWithAttributeAndType | undefined {
  if (ctx instanceof BasicIdentifierContext) {
    return getPartialStandardCriterion(ctx);
  }

  if (ctx instanceof AggrIdentifierContext) {
    return getPartialAggregateCriterion(ctx as EnhancedAggregatedIdentifierContext);
  }
}

// e.g. "average(attribute:type)" -> {attribute, subproperty, type}
function getPartialAggregateCriterion(
  ctx: EnhancedAggregatedIdentifierContext,
): PartialSearchCriterionWithAttributeAndType | undefined {
  if (ctx.getChildCount() !== 6) {
    return;
  }

  const attribute = getIdentifier(ctx.stringIdentifier());
  const subproperty = ctx.SIMPLE_STRING(0).getText();
  const type = ctx.SIMPLE_STRING(1).getText() as SearchCriterion['type'];

  if (!type || !attribute || !subproperty) {
    return;
  }

  return {
    attribute,
    subproperty,
    type,
  };
}

// e.g. "attribute:type" -> {attribute, type}
function getPartialStandardCriterion(
  ctx: BasicIdentifierContext,
): PartialSearchCriterionWithAttributeAndType | undefined {
  if (ctx.getChildCount() !== 3) {
    return;
  }

  const attribute = getIdentifier(ctx.stringIdentifier());

  let type: SearchCriterion['type'];

  if (attribute === KnownAttributes.Owner) {
    type = 'owner';
  } else if (attribute === KnownAttributes.Stage) {
    type = 'stage';
  } else {
    type = ctx.SIMPLE_STRING().getText() as SearchCriterion['type'];
  }

  if (!type || !attribute) {
    return;
  }

  return {
    attribute,
    type,
  };
}

// e.g. "NOT CONTAINS" -> !contains
function getOperator(ctx: ParserRuleContext): SearchCriterion['operator'] | undefined {
  const { type } = getPartialCriterion(ctx.getChild(0)) || {};

  if (type === 'datetime') {
    if (ctx instanceof GtRelationExprContext) {
      return isPeriodValue(getValue(ctx)) ? 'last' : 'after';
    }

    if (ctx instanceof LtRelationExprContext) {
      return 'before';
    }
  }

  if (ctx instanceof ExistsLogicalExprP3Context) {
    return `${ctx.maybeNot().getText() ? '!' : ''}exists` as SearchCriterion['operator'];
  }

  if (ctx instanceof EqRelationExprContext) {
    return '=';
  }

  if (ctx instanceof NeRelationExprContext) {
    return '!=';
  }

  if (ctx instanceof GtRelationExprContext) {
    return '>';
  }

  if (ctx instanceof GeRelationExprContext) {
    return '>=';
  }

  if (ctx instanceof LtRelationExprContext) {
    return '<';
  }

  if (ctx instanceof LeRelationExprContext) {
    return '<=';
  }

  if (ctx instanceof ContainsRelationExprContext) {
    return `${ctx.maybeNot().getText() ? '!' : ''}contains` as SearchCriterion['operator'];
  }

  if (ctx instanceof MatchesRelationExprContext) {
    return 'matches' as SearchCriterion['operator'];
  }
}

// e.g. "attribute:type >= 5" -> 5
function getValue(ctx: ParserRuleContext) {
  const { type } = getPartialCriterion(ctx.getChild(0)) || {};

  if (
    type === 'datetime' &&
    (ctx instanceof GtRelationExprContext || ctx instanceof LtRelationExprContext)
  ) {
    return getStringValue(ctx.string());
  }

  if (
    ctx instanceof GtRelationExprContext ||
    ctx instanceof GeRelationExprContext ||
    ctx instanceof LtRelationExprContext ||
    ctx instanceof LeRelationExprContext
  ) {
    return Number(getStringValue(ctx.string()));
  }

  if (
    ctx instanceof ContainsRelationExprContext ||
    ctx instanceof MatchesRelationExprContext ||
    ctx instanceof EqRelationExprContext ||
    ctx instanceof NeRelationExprContext
  ) {
    const value = getStringValue(ctx.string());

    if (type === 'bool') {
      return stringToBoolean(value);
    }

    if (type === 'int' || type === 'float' || type === 'floatSeries') {
      return Number(value);
    }

    return unescapeSpecialCharacters(value);
  }
}

// merge sibling search queries as long as they use the same operator
function flattenCriteria(
  { criteria, operator }: SearchQuery,
  wrappedInParens?: boolean,
): SearchQuery | SearchCriterion {
  const flattenedCriteria = criteria.reduce(
    (result: Array<SearchQuery | SearchCriterion>, current) => {
      if (isSearchQuery(current) && current.operator === operator) {
        return [...result, ...current.criteria];
      }

      return [...result, current];
    },
    [],
  );

  // in case of a bunch of criteria with the same attribute and operator wrapped in parens, return a single criterion
  // which uses the oneOf/!oneOf operator
  if (
    wrappedInParens &&
    areSameCriteriaWithValues(flattenedCriteria) &&
    isCriterionMergeable(flattenedCriteria[0])
  ) {
    return convertToSingleCriterion(flattenedCriteria, operator);
  }

  return {
    criteria: flattenedCriteria,
    operator,
  };
}

function getParentParenthesesContext(ctx: ParserRuleContext) {
  let parent = ctx.parentCtx;

  while (parent) {
    if (parent instanceof ParenthesesLogicalExprP3Context) {
      return parent;
    }

    parent = parent.parentCtx;
  }
}

function getParentLogicalContextInsideContext(
  ctx: ParserRuleContext,
  wrapperCtx: ParserRuleContext,
) {
  let parent = ctx.parentCtx;

  while (parent && parent !== wrapperCtx) {
    if (parent instanceof OrLogicalExprP1Context || parent instanceof AndLogicalExprP2Context) {
      return parent;
    }

    parent = parent.parentCtx;
  }
}

function areSameCriteriaWithValues(
  criteria: Array<SearchQuery | SearchCriterion>,
): criteria is SearchCriterionWithValue[] {
  return criteria.every((criterion, index) => {
    if (index === 0) {
      return true;
    }

    if (isSearchQuery(criterion) || !criterionHasValue(criterion)) {
      return false;
    }

    const previous = criteria[index - 1];

    if (isSearchQuery(previous) || !criterionHasValue(previous)) {
      return false;
    }

    return (
      criterion.attribute === previous.attribute &&
      criterion.type === previous.type &&
      criterion.operator === previous.operator &&
      criterion.value != null &&
      previous.value != null
    );
  });
}

function convertToSingleCriterion(
  criteria: SearchCriterionWithValue[],
  parentOperator?: SearchQueryOperator,
) {
  const attribute = criteria[0].attribute;
  const type = criteria[0].type;
  const operators = nqlOperatorToOperatorMap[criteria[0].operator];
  const operator = (parentOperator && operators[parentOperator]) || operators['*'];

  return {
    attribute,
    operator,
    type,
    value: criteria.map(({ value }) => value),
  } as SearchCriterion;
}

function isCriterionMergeable(criterion: SearchCriterion) {
  return (
    criterion.type === 'stringSet' ||
    criterion.type === 'experimentState' ||
    criterion.type === 'owner' ||
    criterion.type === 'stage'
  );
}
