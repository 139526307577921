import React from 'react';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { bemBlock, Icon, Layout, Link, Text } from '@neptune/shared/venus-ui';

import ContactLink from 'components/contact-link/ContactLink';

import './OtherFrameworksExample.less';

type SectionLinkModel = {
  href: string;
  title: string;
};

type SectionModel = {
  title: string;
  links: SectionLinkModel[];
};

const block = bemBlock('other-frameworks-example');

export const OtherFrameworksExample = () => {
  return (
    <Layout.Column
      className={block()}
      spacedChildren="md"
      withPadding="md"
      data-role="integration-example"
      data-example="otherFrameworks"
    >
      <Layout.Row spacedChildren="md">
        <Layout.Column spacedChildren="lg">
          <Section section={modelTraining} />
          <Section section={experimentTracking} />
        </Layout.Column>
        <Layout.Column spacedChildren="lg">
          <Section section={hyperparameterOptimization} />
          <Section section={modelVisualisation} />
        </Layout.Column>
      </Layout.Row>
      <Layout.Separator />
      <Layout.Column span="auto">
        Is there any framework missing? <br />
        <ContactLink>Drop us a line</ContactLink>
      </Layout.Column>
    </Layout.Column>
  );
};

const Section: React.FC<{ section: SectionModel }> = ({ section }) => {
  return (
    <Layout.Column spacedChildren="sm" span="auto">
      <Text fontWeight="semibold" color="text-secondary">
        {section.title}
      </Text>
      {section.links.map((link) => (
        <SectionLink key={link.title} link={link} />
      ))}
    </Layout.Column>
  );
};

const SectionLink: React.FC<{ link: SectionLinkModel }> = ({ link }) => {
  return (
    <Link href={link.href} fontWeight="normal" external>
      <Icon glyph="file-alt" /> &nbsp; {link.title}&nbsp;
      <Icon glyph="external-link" />
    </Link>
  );
};

const modelTraining: SectionModel = {
  title: 'Model training',
  links: [
    {
      title: 'Catalyst',
      href: neptuneUrls.integrationCatalystDoc,
    },
    {
      title: 'fastai',
      href: neptuneUrls.integrationFastaiDoc,
    },
    {
      title: 'LightGBM',
      href: neptuneUrls.integrationLightgbmDoc,
    },
    {
      title: 'PyTorch',
      href: neptuneUrls.integrationPyTorchDoc,
    },
    {
      title: 'PyTorch Ignite',
      href: neptuneUrls.integrationPyTorchIgniteDoc,
    },
    {
      title: 'PyTorch Lightning',
      href: neptuneUrls.integrationPyTorchLightningDoc,
    },
    {
      title: 'Scikit-learn',
      href: neptuneUrls.integrationScikitLearnDoc,
    },
    {
      title: 'skorch',
      href: neptuneUrls.integrationSkorchDoc,
    },
    {
      title: 'TensorFlow / Keras',
      href: neptuneUrls.integrationTensorflowKerasDoc,
    },
    {
      title: 'XGBoost',
      href: neptuneUrls.integrationXgboostDoc,
    },
  ],
};

const experimentTracking: SectionModel = {
  title: 'Experiment tracking',
  links: [
    {
      title: 'MLFlow',
      href: neptuneUrls.integrationMlflowDoc,
    },
    {
      title: 'Sacred',
      href: neptuneUrls.integrationSacredDoc,
    },
    {
      title: 'TensorBoard',
      href: neptuneUrls.integrationTensorboardDoc,
    },
  ],
};

const hyperparameterOptimization: SectionModel = {
  title: 'Hyperparameter optimization',
  links: [
    {
      title: 'Keras Tuner',
      href: neptuneUrls.integrationKerasTunerDoc,
    },
    {
      title: 'Optuna',
      href: neptuneUrls.integrationOptunaDoc,
    },
    {
      title: 'Scikit-Optimize',
      href: neptuneUrls.integrationScikitOptimizeDoc,
    },
  ],
};

const modelVisualisation: SectionModel = {
  title: 'Model visualization and debugging',
  links: [
    {
      title: 'Altair',
      href: neptuneUrls.toolAltairDoc,
    },
    {
      title: 'Bokeh',
      href: neptuneUrls.toolBokehDoc,
    },
    {
      title: 'Dalex',
      href: neptuneUrls.integrationDalexDoc,
    },
    {
      title: 'HTML',
      href: neptuneUrls.loggingHtmlDoc,
    },
    {
      title: 'Matplotlib',
      href: neptuneUrls.toolMatplotlibDoc,
    },
    {
      title: 'Pandas',
      href: neptuneUrls.toolPandasDoc,
    },
    {
      title: 'Plotly',
      href: neptuneUrls.toolPlotlyDoc,
    },
  ],
};
