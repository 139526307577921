import React from 'react';

import { HelpLink } from '@neptune/shared/venus-ui';

export const CreateNewRun: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <HelpLink data-role="create-new-run" onClick={onClick}>
      Create a new run
    </HelpLink>
  );
};
