// Libs
import { CommonTokenStream, InputStream } from 'antlr4';
import { NQLLexer } from 'generated/nql/NQLLexer';
// App
import { NQLParser } from 'generated/nql/NQLParser';

import { isBooleanCriterion } from './criterion/boolean-criterion-helpers';
import { isDateTimeCriterion } from './criterion/date-time-criterion-helpers';
import {
  isPluralExperimentStateCriterion,
  isSingularExperimentStateCriterion,
} from './criterion/experiment-state-criterion-helpers';
import { isFloatCriterion } from './criterion/float-criterion-helpers';
import { isFloatSeriesCriterion } from './criterion/float-series-criterion-helpers';
import { isGitRefCriterion } from './criterion/git-ref-criterion.helpers';
import { isIntegerCriterion } from './criterion/integer-criterion-helpers';
import { isOwnerCriterion } from './criterion/owner-criterion-helpers';
import {
  isPluralStageCriterion,
  isSingularStageCriterion,
} from './criterion/stage-criterion-helpers';
import { isStringCriterion } from './criterion/string-criterion-helpers';
import { isStringSeriesCriterion } from './criterion/string-series-criterion-helpers';
import { isNonEmptyStringSetCriterion } from './criterion/string-set-criterion-helpers';
import { ParseQueryVisitor } from './parse-query-visitor';
// Module
import { CriterionType, SearchCriterion, SearchOperator, SearchQuery } from './search-query-model';
import { criterionRequiresValue, isSearchQuery } from './search-query-model-utils';
import { escapeSpecialCharacters } from './special-character-handling';

export abstract class SearchQueryModelConverter {
  static convertNqlToSearchQuery(nql?: string): SearchQuery {
    if (!nql) {
      return {
        criteria: [],
        operator: 'and',
      };
    }

    try {
      const inputStream = new InputStream(nql);
      const lexer = new NQLLexer(inputStream);
      const tokenStream = new CommonTokenStream(lexer);
      const parser = new NQLParser(tokenStream);
      const tree = parser.parse();
      const query = tree.accept(new ParseQueryVisitor()) as any as SearchQuery;

      if (!query.criteria) {
        return {
          criteria: [query],
          operator: 'and',
        };
      }

      return query;
    } catch (e) {
      return {
        criteria: [],
        operator: 'and',
      };
    }
  }

  static convertSearchQueryToNql(
    { criteria, operator }: SearchQuery,
    wrapInParens?: boolean,
  ): string {
    const content = criteria
      .map((entry) => {
        if (isSearchQuery(entry)) {
          return SearchQueryModelConverter.convertSearchQueryToNql(entry, true);
        }

        return convertCriterionToNql(entry);
      })
      .filter(Boolean)
      .join(` ${operator.toUpperCase()} `);

    return wrapInParens && criteria.length > 1 ? `(${content})` : content;
  }
}

function convertCriterionToNql(criterion: SearchCriterion): string {
  const attribute = getNqlAttributeName(criterion);
  const operator = getNqlOperator(criterion);

  if (
    isNonEmptyStringSetCriterion(criterion) ||
    isOwnerCriterion(criterion) ||
    isPluralStageCriterion(criterion) ||
    isPluralExperimentStateCriterion(criterion)
  ) {
    return `(${(criterion.value as string[])
      .map((value: string) => `(${attribute} ${operator} "${escapeSpecialCharacters(value)}")`)
      .join(` ${nqlSearchOperatorMap[criterion.operator] || 'OR'} `)})`;
  }

  if (!criterionRequiresValue(criterion)) {
    return `(${attribute} ${operator})`;
  }

  let value = '';

  if (
    isBooleanCriterion(criterion) ||
    isFloatCriterion(criterion) ||
    isIntegerCriterion(criterion) ||
    isFloatSeriesCriterion(criterion)
  ) {
    value = String(criterion.value);
  }

  if (
    isStringCriterion(criterion) ||
    isGitRefCriterion(criterion) ||
    isStringSeriesCriterion(criterion) ||
    isSingularExperimentStateCriterion(criterion) ||
    isSingularStageCriterion(criterion) ||
    isDateTimeCriterion(criterion)
  ) {
    value = `"${escapeSpecialCharacters(criterion.value)}"`;
  }

  return `(${attribute} ${operator} ${value})`;
}

function getNqlOperator({ operator, type }: SearchCriterion): string {
  return (
    nqlCriterionOperatorMap[operator]?.[type] ||
    nqlCriterionOperatorMap[operator]?.['*'] ||
    operator
  );
}

function getNqlAttributeName(criterion: SearchCriterion): string {
  const type = nqlCriterionTypeMap[criterion.type] || criterion.type;

  if (criterion.subproperty) {
    return `${criterion.subproperty}(\`${criterion.attribute}\`:${type})`;
  }

  return `\`${criterion.attribute}\`:${type}`;
}

const nqlSearchOperatorMap: Partial<Record<SearchOperator, string>> = {
  oneOf: 'OR',
  '!oneOf': 'AND',
  allOf: 'AND',
};

const nqlCriterionTypeMap: Partial<Record<CriterionType, string>> = {
  owner: 'string',
  stage: 'string',
};

const nqlCriterionOperatorMap: Partial<
  Record<SearchOperator, Partial<Record<CriterionType | '*', string>>>
> = {
  contains: {
    '*': 'CONTAINS',
  },
  '!contains': {
    '*': 'NOT CONTAINS',
  },
  oneOf: {
    experimentState: '=',
    owner: '=',
    stage: '=',
    stringSet: 'CONTAINS',
  },
  '!oneOf': {
    owner: '!=',
    stage: '!=',
    stringSet: 'NOT CONTAINS',
  },
  allOf: {
    '*': 'CONTAINS',
  },
  exists: {
    '*': 'EXISTS',
  },
  '!exists': {
    '*': 'NOT EXISTS',
  },
  after: {
    '*': '>',
  },
  before: {
    '*': '<',
  },
  last: {
    '*': '>',
  },
  matches: {
    '*': 'MATCHES',
  },
};
