import React from 'react';

import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { ProjectPermission } from '@neptune/shared/core-permissions-domain';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';

export type OverlaySubView = {
  Component: React.ComponentType<{ project: ProjectWithRole }>;
  label: string;
  icon: GlyphName;
  id: string;
  restricted?: boolean;
  permission?: ProjectPermission;
  noPermissionCopy?: string;
};
type Registry = Record<string, OverlaySubView | undefined>;
const registry: Registry = {};
const registryEntries: OverlaySubView[] = [];

export function registerOverlaySubview(descriptor: OverlaySubView) {
  registry[descriptor.id] = descriptor;
  registryEntries.push(descriptor);
}

export function getRegistry(): Registry {
  return registry;
}

export function getRegistryEntries(): OverlaySubView[] {
  return registryEntries;
}

export type SubviewEntry = {
  id: string;
  icon: GlyphName;
  label: string;
  disabledReason?: string;
  disabled: boolean;
};
