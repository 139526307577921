import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { CREATE_NEW_RUN_MODAL_NAME, integrations } from '@neptune/onboarding-domain';
import {
  IngestUsageIntegrationExample,
  IntegrationExample,
  OtherFrameworksExample,
} from '@neptune/onboarding-ui';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import {
  bemBlock,
  Button,
  GenericLink,
  Layout,
  Menu,
  Modal,
  Section,
  Text,
  View,
} from '@neptune/shared/venus-ui';

import { downloadTextFile } from 'common/download';
import { useApiToken } from 'common/hooks/useApiToken';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';

import config from 'config';

import './CreateNewRunModal.less';

const block = bemBlock('create-new-run-modal');

export const CreateNewRunModal: React.FC = () => {
  const { isOpen, close } = useGenericModal(CREATE_NEW_RUN_MODAL_NAME);
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const { token } = useApiToken();
  const [selected, setSelected] = React.useState(integrations[0]);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);

  return (
    <Modal className={block()} isOpen={isOpen} onClose={close}>
      <Modal.Body>
        <View overflow="hidden" withPadding="none">
          <View.Header>
            <Text fontWeight="semibold" size="md">
              Create a run and log some metadata
            </Text>
          </View.Header>
          <View.Body overflow="hidden">
            {config.withIngestApi ? (
              <Section opaque="grey" width="100%" overflow={['auto', 'hidden']} withCustomScrollbar>
                <IngestUsageIntegrationExample
                  organizationName={organizationName}
                  projectName={projectName}
                />
              </Section>
            ) : (
              <>
                <View.Sidebar variant="narrow">
                  <Menu withPadding={['sm', 'none']} withBorder>
                    {integrations.map((integration) => (
                      <Menu.Item
                        key={integration.id}
                        selected={selected === integration}
                        height={30}
                        children={integration.title}
                        data-id={integration.id}
                        onClick={() => setSelected(integration)}
                      />
                    ))}
                  </Menu>
                </View.Sidebar>
                <View.Content withCustomScrollbar overflow={['auto', 'hidden']}>
                  {selected.id !== 'otherFrameworks' ? (
                    <>
                      <IntegrationExample
                        key={selected.id}
                        integration={selected}
                        apiToken={token}
                        projectIdentifier={projectIdentifier}
                        onDownload={downloadTextFile}
                        context="create-new"
                      />
                      <Layout.Column alignItems="center">
                        <Button<React.ComponentProps<typeof GenericLink>>
                          component={GenericLink}
                          href={selected.link}
                          variant="primary"
                          size="lg"
                          external
                        >
                          Learn more in docs
                        </Button>
                      </Layout.Column>
                    </>
                  ) : (
                    <OtherFrameworksExample />
                  )}
                </View.Content>
              </>
            )}
          </View.Body>
        </View>
      </Modal.Body>
      <Modal.Close className={block('close')} onClose={close} />
    </Modal>
  );
};
