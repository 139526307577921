import { Dictionary, keyBy } from 'lodash';

import { backendClient } from '@neptune/shared/core-apis-backend-domain';
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import {
  ProjectWithRole,
  projectWithRoleFromApiToDomain,
} from '@neptune/shared/core-project-domain';

export type ProjectTab = 'runs' | 'models' | 'metadata' | 'trash' | 'reports';

export type GetProjectsForOrganizationParams = {
  username?: string;
  organizationIdentifier: string;
  isTeam: boolean;
  searchTerm?: string;
  sortBy?: string[];
  sortDirection?: string[];
  offset?: number;
  limit?: number;
  archived?: boolean;
};

export async function getProjectsForOrganization(
  params: GetProjectsForOrganizationParams,
): Promise<{ projects: ProjectWithRole[]; hasMoreProjects: boolean }> {
  const { username, isTeam, organizationIdentifier, ...restParams } = params;
  const { entries, totalItemCount } =
    isTeam || !username
      ? await backendClient.listOrganizationProjects({
          organizationIdentifier,
          ...restParams,
        })
      : await backendClient.listUserProjects({
          projectsForUserMode: 'usersOrganizationIndividual',
          viewedUsername: username,
          ...restParams,
        });

  return {
    projects: entries.map(projectWithRoleFromApiToDomain),
    hasMoreProjects: totalItemCount !== entries.length,
  };
}

export type ProjectSidebarData = Pick<
  ProjectWithRole,
  'avatarUrl' | 'name' | 'organizationName' | 'timeOfCreation' | 'avatarSource'
>;

export type ProjectStats = {
  leaderboardEntryCount?: number;
};

export async function getProjectsStats(
  projectIdentifiers: string[],
): Promise<Dictionary<ProjectStats> | undefined> {
  try {
    const trackingData = await leaderboardClient.listTrackingData({ projectIdentifiers });
    const projectStats = trackingData.map((data) => ({
      identifier: data.identifier,
      leaderboardEntryCount: data.experiments?.count,
    }));
    return keyBy<ProjectStats & { identifier?: string }>(
      projectStats,
      (data) => data.identifier ?? 'undefined',
    );
  } catch (e) {
    return undefined;
  }
}
