import React from 'react';

import {
  getMonitoringTimeTopProjects30Days,
  UsageProjectModel,
} from '@neptune/pricing-commons-domain';

import { fetchStatus as FetchStatus } from 'state/fetch-status';

type ExtendedUsageProjectModel = UsageProjectModel & {
  organizationName: string;
};

type DataWithStatus = {
  fetchStatus: FetchStatus;
  data?: ExtendedUsageProjectModel[];
};

export const useTopUsageProjects = ({
  organizationName,
  sortBy,
}: {
  organizationName: string;
  sortBy: string;
}): DataWithStatus => {
  const [data, setData] = React.useState<ExtendedUsageProjectModel[]>();
  const [status, setStatus] = React.useState<FetchStatus>(FetchStatus.NONE);

  React.useEffect(() => {
    setStatus(FetchStatus.PENDING);
    apiCall();

    async function apiCall() {
      try {
        const data = await getMonitoringTimeTopProjects30Days({
          organizationIdentifier: organizationName,
          sortBy,
        });
        setData(
          data.map((entry) => ({
            ...entry,
            organizationName,
          })),
        );
        setStatus(FetchStatus.SUCCESS);
      } catch (e) {
        setStatus(FetchStatus.FAILED);
      }
    }
  }, [organizationName, sortBy]);

  return {
    fetchStatus: status,
    data,
  };
};
