// Libs
import React, { ErrorInfo } from 'react';

import { ErrorBoundaryMessage } from '@neptune/shared/common-ui';
import { logger, LoggerTags } from '@neptune/shared/core-logger-util';

// App
import { heap } from 'analytics/heap';

type ErrorBoundaryContainerProps = {
  context: 'root' | string;
  silent?: boolean;
};

type ErrorBoundaryContainerState = {
  hasError: boolean;
};

const EVENT_NAME = 'error_boundary_shown';
const SILENT_EVENT_NAME = 'error_boundary_triggered';

// eslint-disable-next-line react/prefer-stateless-function
export class ErrorBoundaryContainer extends React.Component<
  ErrorBoundaryContainerProps,
  ErrorBoundaryContainerState
> {
  constructor(props: ErrorBoundaryContainerProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { context, silent } = this.props;
    const { componentStack } = errorInfo;
    const eventName = silent ? SILENT_EVENT_NAME : EVENT_NAME;

    try {
      heap.track(eventName, {
        context,
      });

      const tags: LoggerTags = {
        fromErrorBoundary: true,
        errorBoundaryContext: context,
      };

      const extraInfo = {
        context,
        fromErrorBoundary: true,
        componentStack,
      };

      logger.logMessage(eventName, extraInfo, tags);

      // For Sentry we log specific message and the exception itself for easier discovery and alerting
      logger.logException(
        error,
        {
          module: context,
          extra: extraInfo,
        },
        tags,
      );
    } catch (e) {}
  }

  onReset = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    if (this.state.hasError) {
      if (this.props.silent) {
        return null;
      }

      return <ErrorBoundaryMessage isRoot={this.props.context === 'root'} onReset={this.onReset} />;
    }

    return this.props.children;
  }
}
