import { scissorsClient as defaultScissorsClient } from '@neptune/shared/core-apis-scissors-domain';

import { hashString as defaultHashString } from './hash-string';

const defaultCache = new Map<string, unknown>();

type Dependencies = {
  cache: Map<string, unknown>;
  scissorsClient: typeof defaultScissorsClient;
  hashString: typeof defaultHashString;
};

type SerializableObject = Record<string, unknown> | unknown[];

export const createKeyValueStore = (dependencies: Partial<Dependencies> = {}) => {
  const {
    cache = defaultCache,
    scissorsClient = defaultScissorsClient,
    hashString = defaultHashString,
  } = dependencies;

  async function persistObject(serializableObject: SerializableObject): Promise<string> {
    const content = JSON.stringify(serializableObject);

    fireAndForgetPersistString(content);

    const key = await hashString(content);

    cache.set(key, serializableObject);

    return key;
  }

  function fireAndForgetPersistString(content: string) {
    (async () => {
      await scissorsClient.snip({
        snipRequest: {
          content,
        },
      });
    })();
  }

  async function loadObject<T>(
    key: string,
    typeGuard: (value: unknown) => value is T,
  ): Promise<T | undefined> {
    if (cache.has(key)) {
      const cached = cache.get(key);
      return typeGuard(cached) ? cached : undefined;
    }

    try {
      const { content } = await scissorsClient.expand({ key });
      const parsed = JSON.parse(content);

      return typeGuard(parsed) ? parsed : undefined;
    } catch (error) {}
  }

  return {
    persistObject,
    loadObject,
  };
};

export const keyValueStore = createKeyValueStore();
