import { DashboardConfigDTO } from 'generated/leaderboard-client';

import { getGlobalStepsRangeFilterEnabled } from 'common/featureFlag';
import { AttributeDefinitionConverter } from 'domain/experiment/attribute';
import { ChartGlobalConfig, DEFAULT_CHART_GLOBAL_CONFIG } from 'domain/widget';

export type DashboardConfig = {
  metricsStepsRange?: {
    from?: number;
    to?: number;
  };

  chartGlobalConfig?: ChartGlobalConfig;
};

type XAxisMode = 'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries';
type AxisScale = 'linear' | 'logarithmic';

export abstract class DashboardConfigModelConverter {
  static fromApiToDomain(config: DashboardConfigDTO | undefined): DashboardConfig | undefined {
    if (!config) {
      return undefined;
    }

    const {
      metricsStepsRange,
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric: xAxisMetric,
    } = config;

    const chartGlobalConfig: ChartGlobalConfig = {
      xAxisScale: (xAxisScale as AxisScale | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.xAxisScale,
      yAxisScale: (yAxisScale as AxisScale | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.yAxisScale,
      xAxisMode: (xAxisMode as XAxisMode | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.xAxisMode,
      xAxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromApiToDomain(xAxisMetric),
      metricsStepsRange:
        getGlobalStepsRangeFilterEnabled() && metricsStepsRange
          ? [metricsStepsRange.from, metricsStepsRange.to]
          : DEFAULT_CHART_GLOBAL_CONFIG.metricsStepsRange,
    };

    return {
      metricsStepsRange: !getGlobalStepsRangeFilterEnabled() ? metricsStepsRange : undefined,
      chartGlobalConfig,
    };
  }

  static fromDomainToApi(config: DashboardConfig | undefined): DashboardConfigDTO | undefined {
    if (!config) {
      return undefined;
    }

    const { chartGlobalConfig } = config;

    const { xAxisScale, yAxisScale, xAxisMode, xAxisMetric, metricsStepsRange } =
      chartGlobalConfig ?? {};

    return {
      metricsStepsRange: getGlobalStepsRangeFilterEnabled()
        ? {
            from: metricsStepsRange?.[0],
            to: metricsStepsRange?.[1],
          }
        : config.metricsStepsRange,
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromDomainToApi(xAxisMetric),
    };
  }
}
