// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { FormikProvider } from 'formik';

import {
  createServiceAccount,
  CreateServiceAccountSchema,
  ServiceAccountReactivateData,
  ServiceAccountsCreateErrors,
} from '@neptune/service-accounts-domain';
// App
import { CreateServiceAccountForm } from '@neptune/service-accounts-ui';
import {
  useDebouncedValidationFormik,
  useGenericModal,
} from '@neptune/shared/common-business-logic';
import { getServiceAccountsLimit } from '@neptune/shared/core-organizations-business-logic';

import { createErrorDescriptor } from 'common/error-handler';
import { getContextOrganization } from 'state/selectors-global';

// Module
import { REACTIVATE_SERVICE_ACCOUNT_MODAL_NAME } from './ReactivateServiceAccountModalContainer';
import {
  MODAL_NAME as SERVICE_ACCOUNTS_LIMIT_MODAL_NAME,
  ServiceAccountsLimitModal,
} from './ServiceAccountsLimitModal';

type WorkspaceCreateServiceAccountModalContainerProps = {
  organizationName: string;
  renderFields: React.FC;
  renderSubmit: React.FC<{ isValid?: boolean }>;
  onClose(): void;
  onSubmit(): void;
};

type ErrorData = {
  code: number;
  deactivated: boolean;
  message: string;
  serviceAccountId: string;
};

export const WorkspaceCreateServiceAccountFormContainer: React.FC<
  WorkspaceCreateServiceAccountModalContainerProps
> = ({ organizationName, renderFields, renderSubmit, onClose, onSubmit }) => {
  const organization = useSelector(getContextOrganization);
  const { open: openLimitModal } = useGenericModal(SERVICE_ACCOUNTS_LIMIT_MODAL_NAME);
  const [deactivatedData, setDeactivatedData] = React.useState<{
    id: string;
    displayName: string;
  }>();
  const { openWithData: requestReactivateServiceAccount } =
    useGenericModal<ServiceAccountReactivateData>(REACTIVATE_SERVICE_ACCOUNT_MODAL_NAME);

  const handleReactivate = React.useCallback(async () => {
    if (!deactivatedData) {
      return;
    }

    onClose();
    await requestReactivateServiceAccount({ ...deactivatedData, organizationId: organizationName });
    setDeactivatedData(undefined);
  }, [deactivatedData, organizationName, requestReactivateServiceAccount, onClose]);

  const handleSubmit = React.useCallback(
    async (values, { setErrors }) => {
      setDeactivatedData(undefined);

      try {
        await createServiceAccount({
          organizationIdentifier: organizationName,
          name: values.name,
          addToAllProjects: values.addToAllProjects,
        });
        onClose();
        onSubmit();
      } catch (e) {
        const errorDescription = createErrorDescriptor(e);
        let errorCode;

        if (errorDescription.code === 409) {
          try {
            const errorData: ErrorData = await errorDescription.error.json();
            errorCode = errorData.deactivated
              ? ServiceAccountsCreateErrors.DEACTIVATED
              : ServiceAccountsCreateErrors.TAKEN;

            if (errorData.deactivated) {
              setDeactivatedData({
                id: errorData.serviceAccountId,
                displayName: `${values.name}@${organizationName}`,
              });
            }
          } catch (e) {
            errorCode = ServiceAccountsCreateErrors.UNKNOWN;
          }
        } else if (errorDescription.code === 422) {
          return openLimitModal();
        } else {
          errorCode = ServiceAccountsCreateErrors.UNKNOWN;
        }

        setErrors({
          name: errorCode,
        });
      }
    },
    [organizationName, onClose, onSubmit, openLimitModal],
  );

  const formik = useDebouncedValidationFormik({
    initialValues: {
      name: '',
      addToAllProjects: false,
    },
    isInitialValid: false,
    onSubmit: handleSubmit,
    validationSchema: CreateServiceAccountSchema,
    validateOnBlur: false,
  });

  return (
    <FormikProvider value={formik}>
      <CreateServiceAccountForm
        renderFields={renderFields}
        renderSubmit={renderSubmit}
        organizationName={organizationName}
        isValid={formik.isValid}
        onReactivate={handleReactivate}
      />
      <ServiceAccountsLimitModal
        limit={organization && getServiceAccountsLimit(organization.traits)}
      />
    </FormikProvider>
  );
};
