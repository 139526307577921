import { escapeRegExp } from 'lodash';

import { isValidRegExp } from '@neptune/shared/common-util';
import { AttributeNameFilter, SearchMode } from '@neptune/shared/search-domain';

import { getCompoundSearch } from 'common/featureFlag';

export type TextFilter = {
  searchType: SearchMode.SUBSTRING;
  value: string;
};

export type CompoundSearchFilter = {
  searchType: SearchMode.REGEX;
  value: CompoundSearchCriterion[];
};

export type CompoundSearchCriterionType = 'regex' | 'operator';

export type CompoundSearchCriterion = {
  type: CompoundSearchCriterionType;
  value: string;
};

const isCompoundSearchCriterion = (value: unknown): value is CompoundSearchCriterion => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'type' in value &&
    ['regex', 'operator'].includes((value as { type: string }).type) &&
    'value' in value &&
    typeof (value as { value: unknown }).value === 'string'
  );
};

export const isArrayOfCompoundSearchCriterion = (
  value: unknown,
): value is CompoundSearchCriterion[] => {
  return Array.isArray(value) && value.every(isCompoundSearchCriterion);
};

export type ComplexSearchFilter = TextFilter | CompoundSearchFilter;

export abstract class ComplexSearchFilterConverter {
  static fromDomainToNameFilter(filter: ComplexSearchFilter): AttributeNameFilter {
    if (filter.searchType === SearchMode.REGEX) {
      const attributeNameFilter: Required<AttributeNameFilter> = {
        mustMatchRegexes: [],
        mustNotMatchRegexes: [],
      };
      let action: 'add' | 'remove' = 'add';

      for (const criterion of filter.value) {
        if (!criterion.value || (criterion.type === 'regex' && !isValidRegExp(criterion.value))) {
          continue;
        }

        if (criterion.type === 'operator') {
          action = criterion.value === 'AND' ? 'add' : 'remove';
        } else {
          action === 'add'
            ? attributeNameFilter.mustMatchRegexes.push(criterion.value)
            : attributeNameFilter.mustNotMatchRegexes.push(criterion.value);
        }
      }

      return attributeNameFilter;
    }

    return {
      mustMatchRegexes: [getCompoundSearch() ? escapeRegExp(filter.value) : filter.value],
    };
  }

  static fromDomainToTitle(filter?: ComplexSearchFilter): string {
    if (!filter) {
      return '';
    }

    if (filter.searchType === SearchMode.REGEX) {
      if (!filter.value) {
        return '';
      }

      return filter.value.map((criterion) => criterion.value).join(' ');
    }

    return filter.value;
  }

  static fromChartFilter(value: string): ComplexSearchFilter {
    return { searchType: SearchMode.SUBSTRING, value };
  }

  static fromRegexCriterions(value: CompoundSearchCriterion[]): ComplexSearchFilter {
    return { searchType: SearchMode.REGEX, value };
  }
}

export function isTextFilter(filter?: ComplexSearchFilter): filter is TextFilter {
  return filter?.searchType === SearchMode.SUBSTRING;
}

export function isRegexFilter(filter?: ComplexSearchFilter): filter is CompoundSearchFilter {
  return filter?.searchType === SearchMode.REGEX;
}

export function trimQuery(query: CompoundSearchCriterion[]): CompoundSearchCriterion[] {
  let newQuery: CompoundSearchCriterion[] = query;

  if (query[query.length - 1]?.type === 'operator') {
    newQuery = query.slice(0, -1);
  } else if (query[query.length - 1]?.type === 'regex' && query[query.length - 1].value === '') {
    newQuery = query.slice(0, -2);
  }

  return newQuery;
}
