import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LeaderboardContext } from '@neptune/shared/entity-leaderboard-domain';
import {
  GroupByContext,
  GroupByField,
  GroupByStorage,
  LeaderboardListGroupKey,
} from '@neptune/shared/leaderboard-domain';

import {
  closeGroupPreview as closeGroupPreviewRaw,
  openGroupPreview as openGroupPreviewRaw,
} from 'state/project-leaderboard/groups-preview/actions';
import type { FetchStatusMap } from 'state/project-leaderboard/groups-preview/reducer';
import {
  getFetchStatusMap,
  getOpenedGroups,
} from 'state/project-leaderboard/groups-preview/selector';
import type { AppState } from 'state/types';
import { getOrderedColumnIdsForCurrentProject } from 'state/ui/leaderboards/ordered-columns/selectors';

import { fetchGroupCompareStatus } from './redux/group-compare-actions';
import { getRunIdsInGroup, getSelectedGroupCount } from './redux/group-compare-selectors';
import { useExperimentsOnlyOptions } from './use-experiments-only-options';
import { useSortOptions } from './use-sort-options';

type UseGroupByReturnValue = {
  fetchStatusMap: FetchStatusMap;
  isEnabled: boolean;
  options: GroupByStorage | undefined;
  setOptions: (options: GroupByStorage) => void;
  openGroupPreview: (
    groupId: string,
    groupKey: LeaderboardListGroupKey[],
    paginationTarget?: { beforeToken: string } | { continuationToken: string },
  ) => void;
  closeGroupPreview: (groupId: string) => void;
  openedGroups: {
    openedGroup: string;
    paginationTarget?: { beforeToken: string } | { continuationToken: string };
  }[];
  isGroupByAvailable: boolean;
  updateGroupCompare: (
    groupBy: GroupByField[],
    selectedIds: string[],
    query?: string,
    openedGroupIds?: string[],
  ) => void;
  selectedGroupCount: Record<string, number | undefined>;
  runIdsInGroup: Record<string, string[]>;
};

export const useGroupBy = (): UseGroupByReturnValue => {
  const dispatch = useDispatch();
  const {
    id: leaderboardIdentifier,
    isGroupByAvailable,
    storageStrategy,
  } = React.useContext(GroupByContext);

  const { entityId, entityType, types, projectIdentifier } = React.useContext(LeaderboardContext);
  const [experimentsOnlyEnabled] = useExperimentsOnlyOptions();

  const { selector, currentQuerySelector, actionCreator } = storageStrategy;
  const [sortOptions] = useSortOptions();

  const options = useSelector(selector);

  const setOptions = React.useCallback(
    (options) => {
      dispatch(actionCreator(options));
    },
    [dispatch, actionCreator],
  );

  const fetchStatusMap = useSelector((state: AppState) =>
    getFetchStatusMap(state, leaderboardIdentifier),
  );

  const openedGroups = useSelector((state: AppState) =>
    getOpenedGroups(state, leaderboardIdentifier),
  );

  const isEnabled = !!options?.groupBy?.length;
  const currentQuery = useSelector(currentQuerySelector);
  const attributesToFetch = useSelector(getOrderedColumnIdsForCurrentProject);

  const selectedGroupCount = useSelector((state: AppState) =>
    getSelectedGroupCount(state, leaderboardIdentifier),
  );

  const runIdsInGroup = useSelector((state: AppState) =>
    getRunIdsInGroup(state, leaderboardIdentifier),
  );

  const closeGroupPreview = React.useCallback(
    (groupId: string) => {
      dispatch(closeGroupPreviewRaw({ leaderboardIdentifier, groupId }));
    },
    [dispatch, leaderboardIdentifier],
  );

  const openGroupPreview = React.useCallback(
    (
      groupId: string,
      groupKey: LeaderboardListGroupKey[],
      paginationTarget?: { beforeToken: string } | { continuationToken: string },
    ) => {
      dispatch(
        openGroupPreviewRaw({
          attributesToFetch,
          currentQuery,
          leaderboardIdentifier,
          groupId,
          groupKey,
          paginationTarget,
          sortOptions,
          entityId,
          entityType,
          types,
          experimentsOnly: Boolean(experimentsOnlyEnabled),
        }),
      );
    },
    [
      dispatch,
      attributesToFetch,
      currentQuery,
      leaderboardIdentifier,
      sortOptions,
      entityId,
      entityType,
      types,
      experimentsOnlyEnabled,
    ],
  );

  const updateGroupCompare = React.useCallback(
    (groupBy: GroupByField[], selectedIds: string[], query?: string, openedGroupIds?: string[]) => {
      dispatch(
        fetchGroupCompareStatus({
          projectIdentifier,
          groupBy,
          selectedIds,
          type: types,
          query,
          leaderboardIdentifier,
          openedGroupIds,
          experimentsOnly: experimentsOnlyEnabled,
        }),
      );
    },
    [dispatch, experimentsOnlyEnabled, leaderboardIdentifier, projectIdentifier, types],
  );

  return {
    openGroupPreview,
    closeGroupPreview,
    updateGroupCompare,
    selectedGroupCount,
    fetchStatusMap,
    isEnabled,
    options,
    setOptions,
    openedGroups,
    isGroupByAvailable,
    runIdsInGroup,
  };
};
