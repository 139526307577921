import React from 'react';

import { useCreateNewRunModal } from '@neptune/onboarding-api';
import { CreateNewRun } from '@neptune/project-ui';

export const CreateNewRunContainer: React.FC = () => {
  const { open } = useCreateNewRunModal();

  return <CreateNewRun onClick={open} />;
};
