import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteProjectServiceAccount, ServiceAccount } from '@neptune/service-accounts-domain';
import { RemoveProjectServiceAccountModal } from '@neptune/service-accounts-ui';
import { useGenericModal } from '@neptune/shared/common-business-logic';

import { showServerErrorModal } from 'state/ui/modals/server-error/actions';

type RemoveProjectServiceAccountModalContainerProps = {
  onSubmit(serviceAccount: ServiceAccount): void;
};

export type RemoveProjectServiceAccountModalParams = {
  serviceAccount: ServiceAccount;
  projectIdentifier: string;
};

export const REMOVE_PROJECT_SERVICE_ACCOUNT_MODAL_NAME =
  'REMOVE_PROJECT_SERVICE_ACCOUNT_MODAL_NAME';

export const RemoveProjectServiceAccountModalContainer: React.FC<
  RemoveProjectServiceAccountModalContainerProps
> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const { isOpen, close, data } = useGenericModal<
    RemoveProjectServiceAccountModalParams | undefined
  >(REMOVE_PROJECT_SERVICE_ACCOUNT_MODAL_NAME);

  const handleSubmit = React.useCallback(async () => {
    if (!data) {
      return;
    }

    try {
      await deleteProjectServiceAccount({
        projectIdentifier: data.projectIdentifier,
        serviceAccountId: data.serviceAccount.id,
      });
      close();
      onSubmit(data.serviceAccount);
    } catch (e) {
      dispatch(showServerErrorModal());
    }
  }, [close, data, dispatch, onSubmit]);

  if (!data) {
    return null;
  }

  return (
    <RemoveProjectServiceAccountModal
      isOpen={isOpen}
      onClose={close}
      onSubmit={handleSubmit}
      projectIdentifier={data.projectIdentifier}
      serviceAccount={data.serviceAccount}
    />
  );
};
