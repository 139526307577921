// Libs
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { isPeriodValue, valueToLabel } from '../period-map';
// App
import { DateTimeCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function isDateTimeCriterion(
  criterion: PartialSearchCriterion,
): criterion is DateTimeCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'datetime';
}

export const dateTimeCriterionHelpers: CriterionHelpers<DateTimeCriterion> = {
  getReadableValue(criterion) {
    return criterion.operator === 'last'
      ? valueToLabel[criterion.value]
      : moment(criterion.value).format(dateTimeFormat);
  },
  getShortReadableValue(criterion) {
    return criterion.operator === 'last'
      ? valueToLabel[criterion.value]
      : moment(criterion.value).format(dateTimeFormat);
  },
  isComplete(criterion) {
    return criterion.operator === 'last'
      ? isPeriodValue(criterion.value)
      : criterion.value != null && moment(criterion.value).isValid();
  },
};
