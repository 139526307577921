import React from 'react';

import { bemBlock, Header, Layout } from '@neptune/shared/venus-ui';

import { ProjectHeaderSectionName } from '../project-header-section-name/ProjectHeaderSectionName';

import './ProjectHeader.less';

type ProjectHeaderProps = {
  createNewRun?: React.ReactElement;
  membersList?: React.ReactElement;
  menu?: React.ReactElement;
  projectSectionName?: string;
};

const block = bemBlock('project-header');

export const ProjectHeader = React.forwardRef<HTMLElement, ProjectHeaderProps>(
  ({ createNewRun, membersList, menu, projectSectionName }, ref) => {
    return (
      <Header
        className={block()}
        span="shrink"
        variant="small"
        height={44}
        justifyContent="center"
        data-role="project-header"
        withPadding={['none', 'sm', 'none', 'none']}
      >
        <Layout.Row alignItems="center" spacedChildren="xl" justifyContent="space-between">
          {projectSectionName && <ProjectHeaderSectionName name={projectSectionName} />}

          <Layout.Element elementRef={ref} id="project-menu-portal" />

          <Layout.Row
            spacedChildren="sm"
            alignItems="center"
            span="shrink"
            withPadding={['sm', 'none']}
          >
            {createNewRun}
            {membersList}
            {menu}
          </Layout.Row>
        </Layout.Row>
      </Header>
    );
  },
);
