// Libs
import React from 'react';

import { backendClient } from '@neptune/shared/core-apis-backend-domain';

// App
import { UsageHistoryPeriod, UsageHistoryPeriodConverter } from 'domain/usage-history';
import { fetchStatus } from 'state/fetch-status';

export type UseUsageHistoryParams = { projectIdentifier: string };

export const useProjectUsageHistory = ({ projectIdentifier }: UseUsageHistoryParams) => {
  const [data, setData] = React.useState<UsageHistoryPeriod[]>([]);
  const [status, setStatus] = React.useState<fetchStatus>(fetchStatus.NONE);

  React.useEffect(() => {
    if (projectIdentifier) {
      fetchData();
    }

    async function fetchData() {
      setStatus(fetchStatus.PENDING);

      try {
        const { results } = await backendClient.projectMonitoringTimeUsage({ projectIdentifier });

        setData(results.map(UsageHistoryPeriodConverter.periodFromApiToDomain));
        setStatus(fetchStatus.SUCCESS);
      } catch (e) {
        setStatus(fetchStatus.FAILED);
      }
    }
  }, [projectIdentifier]);

  return {
    data,
    fetchStatus: status,
  };
};
