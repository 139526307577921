import React from 'react';

import { bemBlock, ButtonProps, TabsMenu, TabsMenuItemProps } from '@neptune/shared/venus-ui';

import './SidebarActionItem.less';

const block = bemBlock('sidebar-action-item');

type SidebarActionItemProps = Pick<TabsMenuItemProps<ButtonProps<{}>>, 'label' | 'icon'> & {
  onToggle?: React.ReactEventHandler;
  pressed?: boolean;
};

export function SidebarActionItem({ label, onToggle, pressed, icon }: SidebarActionItemProps) {
  return (
    <TabsMenu.Item
      className={block({ modifiers: { pressed } })}
      component="button"
      data-selected={pressed}
      size="lg"
      icon={icon}
      radius="sm"
      vertical
      spacedChildren="none"
      selected={pressed}
      label={label}
      onClick={onToggle}
    />
  );
}
