import React from 'react';
import { useSelector } from 'react-redux';

import { ColumnsContext } from '@neptune/shared/columns-domain';
import {
  EnhancedLeaderboardColumn,
  getColumnName,
  LeaderboardColumn,
} from '@neptune/shared/leaderboard-domain';

import { Merge } from 'common/utility-types';

const EMPTY_ARRAY: LeaderboardColumn[] = [];

export const useColumnsContext = () => {
  const { selector } = React.useContext(ColumnsContext);

  const columns = useSelector(selector);

  const enhancedColumns: Merge<LeaderboardColumn, EnhancedLeaderboardColumn>[] = React.useMemo(
    () =>
      columns?.map((column) => ({
        ...column,
        title: getColumnName(column),
      })) ?? [],
    [columns],
  );

  return {
    columns: columns ?? EMPTY_ARRAY,
    enhancedColumns,
  };
};
