import { createSelector } from 'reselect';

import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { createCurrentRouteParamSelector } from '@neptune/shared/routing-business-logic';

import { AppState } from 'state/types';

import { CurrentProjectState } from './reducer';

function getCurrentProjectState(state: any): CurrentProjectState {
  return state.currentProject;
}

function getCurrentProjectError(state: AppState): CurrentProjectState['error'] {
  return getCurrentProjectState(state).error;
}

function getCurrentProjectFetchStatus(state: AppState): CurrentProjectState['fetchStatus'] {
  return getCurrentProjectState(state).fetchStatus;
}

function getCurrentProjectProject(state: AppState): CurrentProjectState['project'] {
  return getCurrentProjectState(state).project;
}

const organizationNameSelector = createCurrentRouteParamSelector('organizationName');
const projectNameSelector = createCurrentRouteParamSelector('projectName');

export const getCurrentProjectFetchState = createSelector(
  getCurrentProjectFetchStatus,
  getCurrentProjectError,
  (fetchStatus, error) => ({
    fetchStatus,
    error,
  }),
);

export const getCurrentProject: (state: AppState) => CurrentProjectState['project'] =
  createSelector(
    organizationNameSelector,
    projectNameSelector,
    getCurrentProjectProject,
    (organizationName, projectName, project) => {
      if (
        project &&
        project.organizationName === organizationName &&
        project.name === projectName
      ) {
        return project;
      }

      return undefined;
    },
  );

export function getCurrentProjectProjectKey(state: AppState): string | undefined {
  return getCurrentProject(state)?.projectKey;
}

export function getCurrentProjectIndicatedEntities(state: AppState) {
  return getCurrentProjectState(state)?.indicatedEntities;
}

export const getCurrentProjectIdentifier = (state: AppState) => {
  const organizationName = organizationNameSelector(state);
  const projectName = projectNameSelector(state);

  return makeProjectIdentifier(organizationName, projectName);
};
