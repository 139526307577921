import { AttributeDefinition } from 'domain/experiment/attribute';

import config from 'config';

const MATCH_ALL = '.';
const isIndexedRegexp = new RegExp(config.indexedAttributesRegex || MATCH_ALL);

// eslint-disable-next-line prettier/prettier
const isSystemRegexp = new RegExp('^sys\/');

export const isAttributeNotIndexed = (name: string) => {
  const isIndexed = isSystemRegexp.test(name) || isIndexedRegexp.test(name);
  return !isIndexed;
};

export const enhanceAttributeWithIsNotIndexed = (attribute: AttributeDefinition) => {
  const isNotIndexed = isAttributeNotIndexed(attribute.name);
  return { ...attribute, isNotIndexed };
};

export const ATTRIBUTE_NOT_INDEXED_TOOLTIP_TEXT =
  'This field is currently not indexed. You can display it as a column, but not sort, filter or group by it. We’re working on the ability to index a larger number of fields..';
