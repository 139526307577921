import React from 'react';

import { useAsyncValue } from '@neptune/shared/common-util';
import { fetchOwners } from '@neptune/shared/organization-domain';

export const useObtainOwnerEmails = (organizationIdentifier?: string) => {
  const resolver = React.useCallback(async () => {
    try {
      if (!organizationIdentifier) {
        return [];
      }

      const owners = await fetchOwners(organizationIdentifier);
      return owners.map(({ email }) => email);
    } catch (e) {
      return [];
    }
  }, [organizationIdentifier]);

  const { value } = useAsyncValue({ resolver });
  return value || [];
};
