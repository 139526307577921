import React from 'react';
import { useSelector } from 'react-redux';

import { IntercomTour } from '@neptune/onboarding-business-logic';
import { useVisibilityChange } from '@neptune/shared/common-ui';
import { OrganizationType, OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import { Entity, getEntityOwner } from '@neptune/shared/entity-domain';

import { isOnPremiseDeployment } from 'common/deploymentModes';
import { getOrganizationsList } from 'state/selectors';
import { getCurrentUserCreated, getCurrentUsername } from 'state/users/current-user/selectors';

const enrollmentDateOfRunDetailsUiTour = new Date('2021-09-15');

export const RunDetailsUiTourTrigger = ({ openedRun }: { openedRun?: Entity }) => {
  const onPrem = isOnPremiseDeployment();
  const username = useSelector(getCurrentUsername);
  const userCreated = useSelector(getCurrentUserCreated);
  const organizationsList: OrganizationWithRole[] = useSelector(getOrganizationsList);
  const [started, setStarted] = React.useState(false);
  const [hidden, setHidden] = React.useState<boolean>();
  useVisibilityChange(setHidden);

  const userBelongsToATeamWorkspace: boolean = organizationsList.some(
    (organization: OrganizationWithRole) => organization.organizationType === OrganizationType.team,
  );
  const userSignedUpAfterEnrollment = userCreated
    ? userCreated > enrollmentDateOfRunDetailsUiTour
    : false;
  const runOwner = openedRun && getEntityOwner(openedRun);
  const userOpenedOwnRun = username === runOwner;

  React.useEffect(() => {
    if (
      onPrem ||
      started ||
      hidden ||
      userBelongsToATeamWorkspace ||
      !userSignedUpAfterEnrollment ||
      !userOpenedOwnRun
    ) {
      return;
    }

    IntercomTour.triggerTour(username, 'onboarding/day_1/run_details_ui_tour');
    setStarted(true);
  }, [
    onPrem,
    started,
    hidden,
    userBelongsToATeamWorkspace,
    userSignedUpAfterEnrollment,
    userOpenedOwnRun,
    username,
  ]);

  return null;
};
