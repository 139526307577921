// Libs
import { memoize } from 'lodash';
import { createSelector } from 'reselect';

import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { getOrderedColumns, orderedColumnsSorter } from '@neptune/shared/columns-business-logic';
import { ColumnsRootKey } from '@neptune/shared/columns-domain';
import {
  LeaderboardColumn,
  localTableStateColumns,
  NEPTUNE_COLUMN_IDS,
} from '@neptune/shared/leaderboard-domain';

import { getActiveView, getDirtyLbView } from 'state/project-leaderboard/lb-views/selectors';
// App
import { AppState } from 'state/types';

// Module
export const getOrderedColumnsForCurrentProject = (state: AppState): LeaderboardColumn[] => {
  const projectIdentifier = getCurrentProjectIdentifier(state);
  const projectColumns = getOrderedColumns(state, ColumnsRootKey.Runs, projectIdentifier);

  if (projectColumns) {
    return projectColumns;
  }

  const activeLbView = getActiveView(state);

  if (activeLbView) {
    return activeLbView.columnList;
  }

  return [];
};

const createTableColumnsSelector = createSelector(
  getOrderedColumnsForCurrentProject,
  (orderedColumns) =>
    memoize(
      (excludedColumnIds: string[]) =>
        orderedColumns
          .filter((column) => {
            return !excludedColumnIds.includes(column.id);
          })
          .sort(orderedColumnsSorter),
      // use custom cache resolver since a fresh IDs array instance is received each time
      (ids: string[]) => ids.join(''),
    ),
);

export const getLeaderboardColumnsForCurrentProject = (state: AppState) => {
  const dirtyLbView = getDirtyLbView(state);
  const excludedColumnIds: string[] = [];

  if (!(dirtyLbView && dirtyLbView.groupOptions)) {
    excludedColumnIds.push(NEPTUNE_COLUMN_IDS.GROUP_BY);
  }

  return createTableColumnsSelector(state)(excludedColumnIds);
};

export const getLeaderboardPublicColumnsForCurrentProject = (state: AppState) => {
  return createTableColumnsSelector(state)(localTableStateColumns);
};

export const getOrderedColumnIdsForCurrentProject = createSelector(
  getOrderedColumnsForCurrentProject,
  (columns) => columns.map(({ id }) => id),
);
