// Libs
import React from 'react';
import { template } from 'lodash';

// Module
import type { Integration } from '@neptune/onboarding-domain';
// App
import { SyntaxHighlighter } from '@neptune/shared/common-ui';
import { bemBlock, Icon, Layout, Link, Text } from '@neptune/shared/venus-ui';

import './IntegrationExample.less';

const block = bemBlock('integration-example');

type IntegrationContentProps = {
  className?: string;
  context?: string;
  integration: Integration;
  apiToken?: string;
  projectIdentifier: string;
  onDownload(name: string, content: string, type?: string): void;
};

export const IntegrationExample: React.FC<IntegrationContentProps> = ({
  className,
  context,
  integration,
  apiToken,
  projectIdentifier,
  onDownload,
}) => {
  const exampleTemplate = template(integration.script);
  const scriptWithData = exampleTemplate({
    apiToken,
    projectIdentifier,
  });
  const handleClick = React.useCallback(() => {
    onDownload(
      integration.scriptName,
      scriptWithData,
      integration.scriptName.endsWith('.py') ? 'text/x-python' : 'text/plain',
    );
  }, [onDownload, integration.scriptName, scriptWithData]);

  let lastStep = 0;

  const getStep = () => {
    lastStep += 1;

    return lastStep;
  };

  return (
    <Layout.Column
      className={block({ extra: className })}
      withPadding="md"
      spacedChildren="md"
      data-role="integration-example"
      data-context={context}
      data-example={integration.id}
    >
      {integration.installCommand && (
        <Layout.Column spacedChildren="sm">
          <Text>
            <Text fontWeight="semibold">Step {getStep()}:</Text> Install the client library
          </Text>
          <SyntaxHighlighter
            language="bash"
            codeToHighlight={integration.installCommand}
            theme="github"
            textId="install-command"
          />
        </Layout.Column>
      )}
      <Layout.Column spacedChildren="sm">
        <Text>
          <Text fontWeight="semibold">Step {getStep()}:</Text> Create a run, then log whatever model
          building metadata you care about.
        </Text>
        <Text>
          <Text italic>{integration.scriptName}</Text>
          <Link data-role="example-download" onClick={handleClick}>
            <Icon glyph="download" />
          </Link>
        </Text>
        <SyntaxHighlighter
          className={block('script')}
          language="python"
          codeToHighlight={scriptWithData}
          data-heap-redact-text
          wrapLines={false}
          theme="github"
          textId="source-code"
        />
      </Layout.Column>
      {integration.runCommand && (
        <Layout.Column spacedChildren="sm">
          <Text>
            <Text fontWeight="semibold">Step {getStep()}:</Text> Run it
          </Text>
          <SyntaxHighlighter
            language="bash"
            codeToHighlight={integration.runCommand}
            theme="github"
            textId="run-command"
          />
        </Layout.Column>
      )}
      <Text>
        <Text fontWeight="semibold">Step {getStep()}:</Text> See your metadata displayed here in
        Neptune!
      </Text>
    </Layout.Column>
  );
};
