import React from 'react';
import { difference, groupBy, identity, keyBy, random, sampleSize, uniq } from 'lodash';

import { pickMostContrastingColor } from 'common/color';

type LabColor = [number, number, number];
export type HexColor = string;
export type Color = [HexColor, LabColor];

export type ChartColor = {
  id: string;
  color?: HexColor;
};

const BASE_COLOR_COUNT = 5;
const COLOR_SAMPLE_SIZE = 8;
const RANDOM_SET_SIZE = 5;

export function getColorByIndex(index: number): string {
  return experimentsColorPalette[index % experimentsColorPalette.length][0];
}

export const ExperimentsColorManagerContext = React.createContext<
  ExperimentsColorManager | undefined
>(undefined);

type ColorId = string;

type ColorMap = Record<
  ColorId,
  {
    color: HexColor;
    neighbourIds: ColorId[];
  }
>;

// Multiple colors with the same cluster id are considered to be in the cluster (neighbours)
export type ColorClusterId = { id: ColorId; clusterId: string };
type ColorNeighboursId = { id: ColorId; neighbourIds: ColorId[] };

export class ExperimentsColorManager {
  private colorMap: ColorMap = {};
  private readonly idMapper: (a: ColorId) => ColorId = identity;

  constructor(idMapper?: (a: ColorId) => ColorId) {
    if (idMapper) {
      this.idMapper = idMapper;
    }
  }

  setColor(id: ColorId, color: HexColor, neighbourColors: ColorId[] = []) {
    this.colorMap[this.idMapper(id)] = {
      color,
      neighbourIds: neighbourColors,
    };
  }

  pickColor(id: ColorId): HexColor {
    const transformedId = this.idMapper(id);
    let colorElement = this.colorMap[transformedId];

    if (!colorElement) {
      this.registerColorNeighbours([
        { id: transformedId, neighbourIds: Object.keys(this.colorMap) },
      ]);
      colorElement = this.colorMap[transformedId];
    }

    return colorElement.color;
  }

  registerColorGroups(colorClusters: ColorClusterId[]) {
    const neighboursMap = this.createNeighboursMap(colorClusters);
    this.registerColorNeighbours(neighboursMap);
  }

  registerColorNeighbours(colorNeighboursMap: ColorNeighboursId[]) {
    this.updateColorMap(colorNeighboursMap);
  }

  private createNeighboursMap(colorClusters: ColorClusterId[]) {
    const neighboursMap: ColorNeighboursId[] = [];

    const colorsByCluster = groupBy(colorClusters, 'clusterId');
    const clustersByColor = groupBy(colorClusters, 'id');
    Object.values(colorsByCluster).forEach((entry) => {
      entry.forEach(({ id }) => {
        const allClusters = clustersByColor[id];
        const neighbourFromAllClusters = uniq(
          allClusters
            .flatMap((cluster) => colorsByCluster[cluster.clusterId].map(({ id }) => id))
            .filter((colorId) => colorId !== id),
        );
        neighboursMap.push({
          id: this.idMapper(id),
          neighbourIds: neighbourFromAllClusters.map(this.idMapper),
        });
      });
    });
    return neighboursMap;
  }

  private updateColorMap(neighboursMap: ColorNeighboursId[]): void {
    const colorsUsedByAllNeighbours = new Set(
      neighboursMap.flatMap((entry) =>
        entry.neighbourIds.map((id) => this.colorMap[id]?.color).filter(Boolean),
      ),
    );
    const startingPalette = experimentsColorPalette
      .slice(0, BASE_COLOR_COUNT)
      .filter(([hex]) => !colorsUsedByAllNeighbours.has(hex));

    const availablePalette = new Set(
      experimentsColorPalette
        .slice(BASE_COLOR_COUNT)
        .filter(([hex]) => !colorsUsedByAllNeighbours.has(hex)),
    );

    neighboursMap.forEach(({ id, neighbourIds }) => {
      if (this.colorMap[id]) {
        return;
      }

      const furthestColor =
        startingPalette.shift() ?? this.getNextFurthestColor(neighbourIds, [...availablePalette]);

      availablePalette.delete(furthestColor);
      this.colorMap[id] = { color: furthestColor[0], neighbourIds };
    });
  }

  private getNextFurthestColor(neighbourIds: ColorId[], availableColors: Color[]): Color {
    const colorsUsedByNeighboursColors = neighbourIds
      .map((neighbourId) => this.colorMap[neighbourId]?.color)
      .filter(Boolean)
      .map((hexColor) => colorMap[hexColor]);

    return getFurthestColors(availableColors, colorsUsedByNeighboursColors, 1)[0];
  }
}

export function getRandomFurthestColor(availableColors: Color[], usedColors: Color[]): Color {
  const furthestColors = getFurthestColors(availableColors, usedColors, RANDOM_SET_SIZE);

  return furthestColors[random(furthestColors.length - 1)];
}

// returns up to "count" best colors to choose from
export function getFurthestColors(
  availableColors: Color[],
  usedColors: Color[],
  count: number,
): Color[] {
  let palette: Color[];

  if (availableColors.length >= COLOR_SAMPLE_SIZE) {
    palette = availableColors.slice(0, COLOR_SAMPLE_SIZE + 1);
  } else {
    const colors = difference(experimentsColorPalette, availableColors);

    palette = [
      ...availableColors,
      ...sampleSize(colors, COLOR_SAMPLE_SIZE - availableColors.length),
    ];
  }

  return findBestColors(usedColors, palette, count);
}

function getDistance([, [l1, a1, b1]]: Color, [, [l2, a2, b2]]: Color): number {
  return (l1 - l2) * (l1 - l2) + (a1 - a2) * (a1 - a2) + (b1 - b2) * (b1 - b2);
}

// returns up to "count" best colors to choose from
function findBestColors(currentColors: Color[], availableColors: Color[], count: number): Color[] {
  if (!currentColors.length) {
    return [availableColors[0]];
  }

  const distances: Array<[number, number]> = [];

  availableColors.forEach((availableColor, index) => {
    let minDistance = Infinity;
    currentColors.forEach((currentColor) => {
      if (!currentColor) {
        return;
      }

      const distance = getDistance(currentColor, availableColor);

      if (distance < minDistance) {
        minDistance = distance;
      }
    });

    distances.push([index, minDistance]);
  });

  distances.sort(([, a], [, b]) => b - a);

  const indices = distances.slice(0, count).map(([index]) => index);

  return availableColors.filter((color, index) => indices.includes(index));
}

export const experimentsColorPalette: Color[] = [
  ['#ab2aca', [44.66, 66.05, -56.32]],
  ['#399df3', [62.1, -7.13, -52.25]],
  ['#179a5c', [56.09, -45.79, 22.78]],
  ['#c6aa2e', [70.56, 1.34, 62.89]],
  ['#d55f4b', [55.52, 46.45, 35.17]],
  ['#314556', [28.15, -4.32, -12.86]],
  ['#613500', [27.33, 17.59, 37.45]],
  ['#bc9dbf', [68.4, 16.32, -13.12]],
  ['#5721ff', [36.5, 64.9, -100.32]],
  ['#8f0046', [30.6, 54.56, 3.09]],
  ['#005100', [29.16, -34.49, 35.23]],
  ['#ff00a9', [56.74, 86.25, -16.9]],
  ['#84754f', [49.93, 1.74, 23.22]],
  ['#16c1c1', [70.62, -40.25, -11.93]],
  ['#726d9b', [47.87, 10.01, -24.43]],
  ['#007f8c', [48.01, -26.8, -15.95]],
  ['#5d2b68', [26.75, 30.89, -26.15]],
  ['#5dc400', [70.88, -51.32, 68.64]],
  ['#ef67ff', [65.74, 67.72, -51.88]],
  ['#995b77', [46.59, 28.94, -4.46]],
  ['#a02500', [36.27, 49.71, 49.9]],
  ['#97b190', [69.54, -14.02, 13.84]],
  ['#a289ff', [63.37, 29.84, -56.32]],
  ['#cd9b83', [68.36, 16.89, 20.24]],
  ['#798b00', [54.83, -18.06, 57.57]],
  ['#f98301', [67.54, 41.76, 73.98]],
  ['#41624f', [38.56, -15.95, 6.85]],
  ['#8199a9', [61.76, -6, -11.37]],
  ['#0061a7', [39.43, -2.98, -45.17]],
  ['#f07fa3', [67.14, 47.06, 1.52]],
  ['#d30052', [45.64, 71.65, 18.68]],
  ['#673c43', [30.87, 20.12, 4.21]],
  ['#5e5b00', [37.82, -5.96, 44.37]],
  ['#b46900', [52.15, 26.51, 59.56]],
  ['#7700c5', [32.9, 64.19, -72.98]],
  ['#bb068d', [42.46, 69.16, -23.34]],
  ['#ff0000', [54.29, 80.81, 69.89]],
  ['#3f3786', [27.55, 21.38, -44.11]],
  ['#8e49fe', [48.01, 57.32, -80.58]],
  ['#067b00', [44.57, -45.72, 47.28]],
  ['#96543d', [43.4, 26.43, 25.9]],
  ['#546fff', [51.19, 26.21, -76.23]],
  ['#a872bd', [55.95, 31.81, -31.1]],
  ['#1ec58e', [70.84, -51.55, 15.83]],
  ['#62877a', [53.24, -15.68, 2.61]],
  ['#404523', [28.14, -6.73, 19.71]],
  ['#ff466f', [59.53, 71.37, 21.27]],
  ['#ca5ea5', [55.53, 49.87, -17.46]],
  ['#69526e', [38.09, 13.98, -12.36]],
  ['#741e1b', [26.4, 37.77, 24.94]],
  ['#8d008c', [32.95, 60.08, -38.39]],
  ['#93a9e3', [69.18, 2.6, -32.1]],
  ['#0000f5', [28.21, 66.26, -108.69]],
  ['#587a41', [47.55, -20.83, 27.03]],
  ['#e200dc', [53.23, 84.78, -52.29]],
  ['#11a100', [57.73, -55.19, 57.58]],
  ['#b6808e', [59.46, 23.02, 1.25]],
  ['#8baf50', [67.25, -24.17, 43.74]],
  ['#00a1c0', [60.56, -27.89, -26.55]],
  ['#506576', [41.55, -4.83, -12.31]],
  ['#5080ad', [51.6, -6.75, -29.37]],
  ['#a5975d', [62.69, -0.8, 32.45]],
  ['#845300', [40.14, 16.96, 48.5]],
  ['#005d61', [35.15, -23.15, -9.45]],
  ['#00a28e', [59.47, -40.7, -0.19]],
  ['#b27757', [55.79, 21.18, 27.46]],
  ['#a53b4d', [41.15, 45.41, 13.92]],
  ['#7e4e9f', [41.54, 32.31, -36.52]],
  ['#c44fff', [56.04, 66.67, -67.74]],
  ['#938cb7', [60.04, 9.54, -21.67]],
  ['#823764', [34.96, 36.92, -10.39]],
  ['#f18560', [67.35, 39.91, 38.95]],
  ['#6c5035', [36.56, 9.43, 20.67]],
  ['#c88fed', [67.78, 34.82, -39.26]],
  ['#cd0016', [43.7, 68.72, 51.26]],
  ['#5456aa', [39.72, 17.55, -46.26]],
  ['#ff66ce', [66.15, 66.9, -23.87]],
  ['#ff5d1a', [61.79, 60.88, 66.47]],
  ['#a78500', [57.48, 5.84, 61.81]],
  ['#bf4901', [47.46, 46.53, 58.09]],
  ['#007e60', [46.53, -36.94, 7.61]],
  ['#cb5372', [52.41, 50.52, 8.05]],
  ['#004f31', [28.88, -28.68, 11.47]],
  ['#749765', [58.84, -19.91, 22.31]],
  ['#86b3af', [69.63, -16.22, -3.1]],
  ['#8f7591', [52.49, 14.34, -11.28]],
  ['#cb8a3d', [63.17, 20.44, 50.19]],
  ['#126a33', [39.01, -35.88, 23.34]],
  ['#0d457d', [28.38, 0.03, -37.36]],
  ['#7689d8', [58.16, 8.91, -43.3]],
  ['#483e5e', [28.31, 10.2, -17.55]],
  ['#3eb756', [66.2, -50.33, 38.6]],
  ['#71b4d6', [69.8, -15.23, -24.28]],
  ['#9d4692', [43.7, 44.31, -24.98]],
  ['#d584c7', [65.6, 39.17, -21.44]],
  ['#0057f4', [41.71, 29.46, -85.7]],
  ['#807219', [48.17, -1.63, 47.23]],
  ['#a10027', [34.09, 57.87, 29.02]],
  ['#76015b', [25.82, 49.92, -18.71]],
  ['#823915', [33.87, 30.57, 36.58]],
  ['#8c71cd', [53.36, 26.25, -44.5]],
  ['#5f633e', [40.87, -6.48, 20.5]],
  ['#62808c', [51.59, -8.84, -10.2]],
  ['#5900c9', [28.81, 61.35, -82.24]],
  ['#455179', [34.77, 3.7, -24.59]],
  ['#9a03ed', [41.83, 74.77, -81.07]],
  ['#d86d26', [58.38, 39.81, 56.39]],
  ['#0078d6', [48.93, -0.33, -57.11]],
  ['#66aa89', [64.39, -28.33, 9.98]],
  ['#976a67', [49.54, 18.24, 9.27]],
  ['#5f3b8a', [32.31, 29.1, -38.72]],
  ['#6e223e', [26.47, 35.96, 1.46]],
  ['#0b6784', [39.91, -16.99, -23.55]],
  ['#d87b7b', [62.24, 36.94, 16.71]],
  ['#e63640', [52.47, 67.11, 38.86]],
  ['#3b6300', [37.69, -25.76, 42.8]],
  ['#d72582', [49.25, 70.38, -5.78]],
  ['#740096', [28.73, 56.68, -51.64]],
  ['#2946b2', [33.1, 20.87, -61.83]],
  ['#46716f', [44.47, -15.93, -3.88]],
  ['#ff655a', [63.51, 59.21, 38.25]],
  ['#b31060', [39.77, 62.97, 0.48]],
  ['#9f9e00', [63.53, -10.57, 65.41]],
  ['#7b00fc', [38.39, 73.69, -95.37]],
  ['#bd4136', [46.09, 50.15, 35.01]],
  ['#cb5fd0', [57.38, 54.96, -39.25]],
  ['#7d545b', [40.5, 18.36, 3.22]],
  ['#509036', [54.03, -34.35, 39.87]],
  ['#70a600', [62.46, -33.82, 62.79]],
  ['#56989d', [58.62, -20.96, -9.59]],
  ['#ec5796', [59.29, 62.18, -2.47]],
  ['#3c4b00', [29.54, -13.9, 36.84]],
  ['#254a45', [28.59, -14.97, -1.36]],
  ['#a6a6c3', [68.84, 4.44, -14.92]],
  ['#843a3a', [34.85, 32.49, 16.28]],
  ['#5f7400', [45.72, -18.41, 49.97]],
  ['#554400', [29.83, 2.65, 38.44]],
  ['#00b9ff', [70.06, -21.26, -46.31]],
  ['#b25c5a', [49.74, 35.48, 17.92]],
  ['#5e71be', [48.82, 9.53, -43.4]],
  ['#ad9ee2', [68.33, 16.35, -32.65]],
  ['#98086e', [34.2, 58.47, -17.43]],
  ['#b2457c', [46.17, 49.1, -7.58]],
  ['#6c98c5', [60.98, -6.26, -28.37]],
  ['#9d55d1', [49.35, 46.74, -52.98]],
  ['#55374f', [27.39, 16.99, -9.04]],
  ['#593927', [27.52, 12.99, 17.29]],
  ['#996733', [48.27, 16.88, 37.14]],
  ['#7a3b7f', [35.5, 35.75, -26.6]],
  ['#6754e7', [44.54, 38.73, -73.56]],
  ['#9c4903', [41.5, 32.94, 50.66]],
  ['#d292af', [67.68, 28.01, -4.98]],
  ['#b378a5', [57.82, 28.95, -14.38]],
  ['#8274fe', [55.58, 32.67, -68.4]],
  ['#77bb7c', [70.14, -32.25, 24.92]],
  ['#875c8a', [45.09, 24.15, -18.38]],
  ['#858a41', [55.85, -9.95, 37.83]],
  ['#de9702', [68.35, 20.74, 72.1]],
  ['#625587', [39.07, 14.27, -26.23]],
  ['#870000', [27.82, 50.38, 41.6]],
  ['#e24415', [53.07, 60.63, 59.74]],
  ['#3f5a2e', [35.2, -17.26, 21.93]],
  ['#1d823f', [47.72, -40.99, 27.64]],
  ['#7682a0', [54.2, 0.73, -17.67]],
  ['#4c6795', [42.99, -0.05, -28.71]],
  ['#c000b9', [45.18, 74.84, -45.33]],
  ['#a968ff', [56.89, 48.95, -66.59]],
  ['#744cc0', [41.63, 37.04, -55.88]],
  ['#de00ad', [50.44, 79.68, -29.55]],
  ['#a77f3c', [56.17, 10.56, 41.78]],
  ['#ff3aec', [61.97, 84.14, -47.1]],
  ['#8b24b4', [37.44, 57.88, -55.4]],
  ['#2e8980', [51.67, -29.42, -3.46]],
  ['#b35f35', [50.04, 32.36, 39.26]],
  ['#00516c', [31.37, -13.88, -22.05]],
  ['#ce7091', [59.03, 40.59, -0.62]],
  ['#fa007c', [54.55, 82.52, 6.54]],
  ['#96b700', [69.99, -26.15, 69.58]],
  ['#3e02d6', [27.27, 61.33, -92.45]],
  ['#006857', [38.73, -30.4, 1.91]],
  ['#ce03f3', [50.96, 83.59, -69.15]],
  ['#71815e', [51.94, -11.07, 17.01]],
  ['#769fff', [65.71, 6.37, -52.89]],
  ['#4c8760', [51.48, -27.1, 14.78]],
  ['#58bda7', [70.17, -34.98, 1.71]],
  ['#0392cc', [56.37, -17.23, -39.72]],
  ['#769c93', [61.34, -15.04, 0.25]],
  ['#79622d', [43.02, 4.71, 33.18]],
  ['#acad5e', [69.34, -8.86, 40.16]],
  ['#c8a066', [68.65, 9.97, 36]],
  ['#02bedb', [70.33, -33.35, -26.48]],
  ['#8a68a8', [49.44, 23.54, -29.38]],
  ['#9d3d2d', [39.59, 40.06, 31.12]],
  ['#84624a', [44.67, 11.79, 19.32]],
  ['#ab55b5', [49.95, 46.33, -35.77]],
  ['#652854', [26.6, 32.24, -13.23]],
  ['#af8cc6', [63.15, 21.78, -25.07]],
  ['#63aeb9', [66.57, -22.12, -13.34]],
  ['#af8d6e', [61.29, 10.06, 21.7]],
  ['#8e465b', [39.65, 32.86, 2.41]],
  ['#007ba2', [47.48, -18.59, -29.59]],
  ['#5c139d', [26.42, 50.01, -59.9]],
  ['#682e18', [27, 25.39, 26.57]],
  ['#438ba3', [54, -17.95, -20.02]],
  ['#2687ff', [55.95, 6.49, -68.64]],
  ['#ea48bb', [58, 70.24, -25.85]],
  ['#840026', [27.44, 50.3, 20.01]],
  ['#b70fff', [48.18, 80.49, -80.56]],
  ['#ff6d83', [65.43, 57.94, 17.62]],
  ['#966800', [47.89, 13.66, 54.6]],
  ['#d0865e', [63.18, 26.21, 33.77]],
  ['#56745a', [45.92, -15.57, 10.76]],
  ['#a95f96', [50.55, 36.48, -16.74]],
  ['#6f0a72', [26.08, 49.19, -33.39]],
  ['#a489a6', [60.34, 14.49, -11.4]],
  ['#00ad7b', [62.64, -48.39, 14.74]],
  ['#bb1f38', [41.66, 60.51, 28.68]],
  ['#67a351', [61.46, -32.11, 35.84]],
  ['#bc419f', [48.36, 57.29, -25.08]],
  ['#92acc6', [69.04, -4.84, -16.47]],
  ['#7f4f6e', [40.04, 24.34, -9.11]],
  ['#aa7fde', [60.41, 31.17, -42.73]],
  ['#d271ff', [63.54, 55.53, -55.66]],
  ['#d69395', [67.8, 26.23, 9.74]],
  ['#5c3eac', [34.67, 34.13, -55.5]],
  ['#b99638', [64.08, 6.22, 52.5]],
  ['#e735ff', [58.41, 82.37, -63.59]],
  ['#e2606c', [58.24, 52.32, 20.25]],
  ['#3a5761', [35, -9.03, -9.44]],
  ['#70b0f2', [69.55, -7.09, -39.87]],
  ['#b2687b', [52.98, 32.09, 2.91]],
  ['#746582', [45.04, 10.64, -13.92]],
  ['#982e5a', [37.05, 47.28, -0.43]],
  ['#3c63c1', [43.04, 10.84, -54.48]],
  ['#bf8579', [61.28, 21.82, 15.91]],
  ['#e172b1', [62.91, 49.43, -12.89]],
  ['#f60256', [53.07, 79.91, 27.46]],
  ['#6c4912', [34.29, 11.63, 36.73]],
  ['#9ca0ff', [68.5, 15.8, -48.29]],
  ['#724132', [33.41, 20.61, 18.74]],
  ['#00b821', [65.32, -61.24, 57.95]],
  ['#06aaab', [62.78, -36.92, -11.5]],
  ['#7265b9', [46.99, 20.91, -43.28]],
  ['#2b2dfe', [34.86, 56.95, -102.6]],
  ['#fe4633', [58.64, 69.1, 53.76]],
  ['#919d76', [62.98, -9.65, 18.98]],
  ['#595028', [34.19, -0.59, 24.75]],
  ['#149371', [54.1, -39.99, 8.5]],
  ['#824d25', [38.55, 20.31, 32.95]],
  ['#656c2a', [43.92, -10.19, 34.99]],
  ['#aeaa7e', [69.11, -3.78, 23.32]],
  ['#a51c96', [39.67, 61.91, -33.55]],
  ['#bc2e05', [43, 55.8, 54.67]],
  ['#344f38', [30.9, -14.78, 10.25]],
  ['#7e7eb8', [54.56, 10.35, -30.8]],
  ['#5f416f', [32.48, 20.34, -21.66]],
];

export const colorMap = keyBy(experimentsColorPalette, ([rgb]) => rgb);

export const colorPickerColors = experimentsColorPalette.slice(0, 16).map(([hexColor]) => hexColor);

export const hashText = (name: string) => {
  let hashValue = 0;

  for (let i = 0; i < name.length; i++) {
    hashValue = name.charCodeAt(i) * (i + 1);
  }

  return hashValue;
};

export const getColorByText = (text: string): string => {
  const index = Math.abs(hashText(text)) % experimentsColorPalette.length;

  return experimentsColorPalette[index][0];
};

export const getTextColorBasedOnBackgroundColor = (color: string): HexColor => {
  return pickMostContrastingColor(color);
};
